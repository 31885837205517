import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class CommonService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  getMedicines(text, onResponse, onError) {
    let url = APIConfig.detailedAssessment.getMedicines.format(text);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchMedicineByCode(id, onResponse, onError) {
    var body = {
      codes: [id],
    };
    let url = APIConfig.detailedAssessment.fetchMedicineByCode;

    return this.apiService.post(url, body, onResponse, onError);
  }
  addressUpdate(id, body, onResponse, onError) {
    let url = APIConfig.patient.address.format(id);
    return this.apiService.put(url, body, onResponse, onError);
  }
  fetchAddress(id, onResponse, onError) {
    let url = APIConfig.patient.address.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  addressNew(id, body, onResponse, onError) {
    let url = APIConfig.patient.address.format(id);
    return this.apiService.post(url, body, onResponse, onError);
  }
  searchByPincode(id, onResponse, onError) {
    let url = APIConfig.patient.pinCode.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  migrationHealthPlanService = (id, onResponse, onError) => {
    let url = APIConfig.healthPlanMigration.check.format(id);
    return this.apiService.get(url, onResponse, onError);
  };

  getPrimaryAddress = (id, onResponse, onError) => {
    let url = APIConfig.familyPlan.primaryAddress.format(id);
    return this.apiService.get(url, onResponse, onError);
  };

  //update primary address
  updatePrimaryAddress = (pId, aId, body, onResponse, onError) => {
    let url = APIConfig.familyPlan.updatePrimaryAddress.format(aId, pId);
    return this.apiService.put(url, body, onResponse, onError);
  };
  //get all family members
  getAllFamilyMembers = (pId, onResponse, onError) => {
    let url = APIConfig.familyPlan.getFamilyMembers.format(pId);
    return this.apiService.get(url, onResponse, onError);
  };

  //get all minor members
  getMinorMember = (pId, onResponse, onError) => {
    let url = APIConfig.familyPlan.getMinorMembers;
    return this.apiService.post(url, pId, onResponse, onError);
  };

  //add delivery due date
  addDeliveryDueDate = (body, onResponse, onError) => {
    let url = APIConfig.patient.deliveryDueDate;
    return this.apiService.post(url, body, onResponse, onError);
  };

  //add delivery due date
  updateDeliveryDueDate = (body, onResponse, onError) => {
    let url = APIConfig.patient.deliveryDueDate;
    return this.apiService.put(url, body, onResponse, onError);
  };

  //add minor member
  addMinorMember = (body, onResponse, onError) => {
    let url = APIConfig.familyPlan.addMinorMember;
    return this.apiService.post(url, body, onResponse, onError);
  };

  //add primary address
  addPrimaryAddress = (pId, body, onResponse, onError) => {
    let url = APIConfig.familyPlan.familyAddress;
    return this.apiService.post(url, body, onResponse, onError);
  };

  //get patient wallet info
  getWalletInfo = (id, onResponse, onError) => {
    let url = APIConfig.patient.walletInfo.format(id);
    return this.apiService.get(url, onResponse, onError);
  };
  //servetel.com agent
  getServetelAgent = (phoneNo, onResponse, onError) => {
    let url = APIConfig.callServetel.getAgent.format(phoneNo);
    return this.apiService.get(url, onResponse, onError);
  };
  callServetel = (requestBody, onResponse, onError) => {
    let url = APIConfig.callServetel.call;
    return this.apiService.postv2(url, requestBody, onResponse, onError);
  };
  fetchSparktgAgent(id, onResponse, onError) {
    let url = APIConfig.callSparktg.getAgent.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  callSparktg = (requestBody, onResponse, onError) => {
    let url = APIConfig.callSparktg.call;
    return this.apiService.postv2(url, requestBody, onResponse, onError);
  };
}

export default CommonService;
