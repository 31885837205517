import React, { useRef, useState, useEffect, useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
    TextField,
    InputAdornment,
    Select,
    MenuItem,
    Menu,
    IconButton,
    InputLabel,
    FormControl,
    Box,
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";



import filterApplied from "../../../../../assets/icons/filter-applied-icon.svg";
import calender from "../../../../../assets/icons/calender.svg";
import funnelIcon from "../../../../../assets/icons/funnel.svg";
import transactionFilter from "../../../../../assets/icons/transactionFilter.svg";
import uploadIcon from "../../../../../assets/icons/orange-upload-icon.svg";
import arrowCircleRightIcon from "../../../../../assets/icons/arrow_circle_right_icon.svg";
import calendarIconSvg from "../../../../../assets/icons/arrow_circle_right_icon.svg";
import checkCircle from "../../../../../assets/icons/check_circle.svg";
import helpIcon from "../../../../../assets/icons/help.svg";
import FontIcon from "react-md/lib/FontIcons";
import OptionsIcon from "react-md-icon/dist/RoundMoreVert";
import NavigoAPIServices from "../services/navigoAPIServices";
import EventService from "../../../../../service/event/EventService";
import moment from "moment";
import Button from "react-md/lib/Buttons/Button";
import AuthenticationService from "../../../../../service/auth/AuthenticationService";
import GlobalSkeletonLoader from "../../../../../components/common/GlobalSkeletonLoader";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import PatientsService from "../../../../ops/modules/patients/service/PatientsService";
import { CircularProgress } from "react-md";
import { green100 } from "material-ui/styles/colors";
import CommonService from "../../../../ops/modules/patients/modules/new-bridge/services/CommonService";
import Helper from "util/Helper";

const claimStatus = [
    { value: 1, name: "Received" },
    { value: 2, name: "Approved" },
    { value: 3, name: "Rejected" },
    { value: 4, name: "Processed" },
    { value: 5, name: "Appealed" },
    { value: 6, name: "On Hold" },
];
const generalNavigoStatus = [
    // { value: 1, name: "In progress" },
];

function ClaimsDashboardFaqs(props) {
    

    const navigoAPIServices = new NavigoAPIServices();
    const authenticationService = new AuthenticationService();
    const patientService = new PatientsService();
    const commonServices = new CommonService();
    const helper = new Helper();

    // useEffect(() => {
    //     getAllFaqs();
    // }, []);


    const handleBackClick = () => {
        props.history.push("/miscellaneous/claims-dashboard");
    };

    return (
        <div className="navigo-appoint-container">
            <div className="navigo-appoint-section-3">
                <div className="navigo-appoint-section-1" onClick={handleBackClick}>
                    <FontIcon>arrow_back</FontIcon>
                </div>
                <h1 className="navigo-appoint-section-2">Frequently asked questions (FAQs)</h1>
            </div>


        </div>
    );
}
export default ClaimsDashboardFaqs;
